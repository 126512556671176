<template>
  <v-dialog v-model="dialog" max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="init"
        class="mt-4 text-capitalize"
        color="primary"
        rounded
      >
        Concluir <v-icon color="white">mdi-chevron-right</v-icon>
      </v-btn>
    </template>

    <v-card v-if="!validate && dialog">
      <v-col class="pt-8" v-if="isntMarried_and_hasSpouse || !hasTenantLocatario">
        <v-row class="pb-4" v-if="isntMarried_and_hasSpouse">
          <v-icon size="20" color="error" class="ml-2">mdi-close-thick</v-icon>
          <h6>O locatário não possui estado civil de casado ou união estável,</h6>
          <h6 class="pl-8">porém existe uma parte pretendente à conjuge do locatário</h6>
        </v-row>
        <v-row class="pb-4" v-if="!hasTenantLocatario">
          <v-icon size="20" color="error" class="ml-2">mdi-close-thick</v-icon>
          <h6>Para concluir, é obrigatório informar as pessoas que farão parte da locação.</h6>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
      </v-col>

      <v-expansion-panels v-model="openedPanel" accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center">
              <h6 class="bold">Validando dados do imóvel</h6>
              <v-progress-circular
                :size="20"
                color="primary"
                indeterminate
                class="ml-2"
                v-if=realty.validating
              ></v-progress-circular>
              <v-icon size="30" color="success" v-if="!realty.validating && !realty.hasErrors" class="ml-2">mdi-check-bold</v-icon>
              <v-icon size="30" color="error" v-if="!realty.validating && realty.hasErrors" class="ml-2">mdi-close-thick</v-icon>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if=realty.hasErrors>
              <v-col>
                <v-row class="mb-1">
                  <h6 class="bold">{{ textVerificar }}</h6>
                </v-row>
                <v-row v-for="(error, i) in realty.errors" :key=i>
                  <span class="small">- {{error[0]}}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-for="(t, i) in tenants" :key=i v-if=!reload>
          <v-expansion-panel-header>
            <v-row align="center">
              <h6 class="bold">Validando dados da parte {{notEmpty(t.personalData.nome) ? '('+t.personalData.nome+')' : "(Nome não informado)"}}</h6>
              <v-progress-circular
                :size="20"
                color="primary"
                indeterminate
                class="ml-2"
                v-if="tenant[i].validating"
              ></v-progress-circular>
              <v-icon size="30" color="success" v-if="!tenant[i].validating && !tenant[i].hasErrors" class="ml-2">mdi-check-bold</v-icon>
              <v-icon size="30" color="error" v-if="!tenant[i].validating && tenant[i].hasErrors" class="ml-2">mdi-close-thick</v-icon>
          </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if=tenant[i].hasErrors>
              <v-col>
                <v-row class="mb-1">
                  <h6 class="bold">{{ textVerificar }}</h6>
                </v-row>
                <v-row v-for="(error, i) in tenant[i].errors" :key=i>
                  <span class="small text_error">- {{error[0]}}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="dialog = false"
          rounded
        >
          Fechar
        </v-btn>
        <v-btn
          v-if="allSuccess"
          color="primary"
          @click=procedToConclude
          rounded
        >
          Continuar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else-if="dialog">
      <v-card-text class="pl-2 pr-2 pt-1 pb-1">
        <v-container>
          <v-row>
            <v-col class="pa-0 ma-0">
              <h5 class="primary--text bold">Tem certeza que deseja concluir esta solicitação?</h5>
              <p>Esta ação não poderá ser desfeita!</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="dialog = false"
          rounded
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          @click=concludeSolicitation
          rounded
          :loading=loading
        >
          Concluir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'RequestComplete',
  data: () => ({
    dialog: false,
    realty: {
      validating: false,
      hasErrors: false,
      errors: null
    },
    tenant: [],
    validate: false,
    reload: false,
    openedPanel: false,
    allSuccess: false,
    hasOneTenant: null,
    hasOneSpouse: null,
    isMarried: false,
    loading: false
  }),
  computed: {
    ...mapGetters(['tenants']),
    notValidating () {
      let tenant_validating = false
      this.tenant.forEach((t, i) => {
        if (t.validating) {
          tenant_validating = true
        }
      })
      return !this.realty.validating && !tenant_validating
    },
    isntMarried_and_hasSpouse () {
      return !this.isMarried && this.hasOneSpouse !== null
    },
    hasTenantLocatario() {
      return this.tenants.some(tenant => tenant.personalData.pretensao_id === 1);
    },
    textVerificar () {
      return 'Favor verificar os campos obrigatórios antes de concluir:'
    }
  },
  watch: {
    dialog (val) {
      if (val === false) {
        this.resetValidation()
      }
    }
  },
  methods: {
    ...mapActions([
      'saveRequest',
      'validateRealty',
      'validateTenant'
    ]),
    ...mapMutations([
      'setRequestSituation'
    ]),
    init () {
      this.tenants.forEach((tenant, i) => {
        // 1 = locatário
        if (tenant.personalData.pretensao_id == 1) {
          if (this.hasOneTenant == null) {
            this.hasOneTenant = true
          } else if (this.hasOneTenant == true) {
            this.hasOneTenant = false
          }
        }

        this.tenant[i] = {
          validating: false,
          hasErrors: false,
          errors: null
        }
      })

      if (this.hasOneTenant == null) {
        this.hasOneTenant = false
      }

      if (this.hasOneTenant == true) {
        const tenant_tenant = this.tenants.find(tenant => {
          return tenant.personalData.pretensao_id == 1
        })

        // 2 = casado && 6 = uniao estavel
        this.isMarried = (tenant_tenant.personalData.estado_civil_id == 2 || tenant_tenant.personalData.estado_civil_id == 6)

        this.tenants.forEach((tenant, i) => {
          // 3 = Conjuge do locatário
          if (tenant.personalData.pretensao_id == 3) {
            if (this.hasOneSpouse == null) {
              this.hasOneSpouse = true
            } else if (this.hasOneSpouse == true) {
              this.hasOneSpouse = false
            }
          }
        })

        if (this.isMarried && this.hasOneSpouse == null) {
          this.hasOneSpouse = false
        }
      }

      this.validateSolicitation()
    },
    validateSolicitation (val) {
      this.realty.validating = true
      this.validateRealty().then(resp => {
        if (resp.status == 422) {
          this.realty.hasErrors = true
          this.realty.errors = resp.data.errors
        }
        this.realty.validating = false
        this.allSuccess = this.setAllSuccess()
      })
      this.tenants.forEach((t, i) => {
        this.tenant[i].validating = true
        this.validateTenant(t).then(resp => {
          if (resp.status == 422) {
            this.tenant[i].hasErrors = true
            this.tenant[i].errors = resp.data.errors
          }
          this.tenant[i].validating = false
          this.reloadContainer()
          this.allSuccess = this.setAllSuccess()
        })
      })
    },
    reloadContainer () {
      this.reload = true
      this.reload = false
    },
    concludeSolicitation () {
      this.loading = true
      this.setRequestSituation(2)
      this.saveRequest().then(() => {
        this.$router.push('/solicitacoes')
        this.dialog = false
      })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setAllSuccess () {
      let tenant_hasErrors = false
      let tenant_validating = false
      this.tenant.forEach((t, i) => {
        if (t.hasErrors) {
          tenant_hasErrors = true
        }
        if (t.validating) {
          tenant_validating = true
        }
      })
      return !this.realty.validating && !this.realty.hasErrors && !tenant_hasErrors && !tenant_validating && !this.isntMarried_and_hasSpouse && this.hasTenantLocatario
    },
    procedToConclude () {
      this.validate = true
    },
    resetValidation () {
      this.dialog = false
      this.realty = {
        validating: false,
        hasErrors: false,
        errors: null
      }
      this.tenant = []
      this.validate = false
      this.reload = false
      this.openedPanel = false
      this.allSuccess = false
      this.hasOneTenant = null
      this.hasOneSpouse = null
      this.isMarried = false
    }
  }
}
</script>
